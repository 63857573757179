import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { getConversionRate } from 'utils';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginBottom: '0.5em',
        marginTop: '3px'
    },
}));

const Footer = ({ inputSelectRetirementYear, inputRetirementComplementary }) => {
    const eveaSpan = inputRetirementComplementary * 12 / getConversionRate(inputSelectRetirementYear);
    const classes = useStyles();
    return(
        <h2 className={classes.title}>Estimation du capital cible {Math.round(eveaSpan)}€</h2>
    )
};

export default Footer;

Footer.propTypes = {
    inputSelectRetirementYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    inputRetirementComplementary: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
};