import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        padding: '1em',

    },
    titleWrapper: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginBottom: '0.5em',
    },
    title: {
        fontSize: '1.3em',
        marginBottom: '2px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2em',
        },
    }

}));

const Footer = ({
    inputTotalCost,
    inputTotalMonthlyPayment,
    inputSlider,
    error
}) => {
    const classes = useStyles();
    return(
        <Card className={classes.footerWrapper}>
            {!error && (
                <>
                    <div className={classes.titleWrapper}>
                        <h3 className={classes.title}>Part financée par evea : {inputTotalCost - inputTotalMonthlyPayment - inputSlider} € </h3>
                        <span>capital cible</span>
                    </div>
                </>
            )}
            <p>avertissement : le présent simulateur offert par evea by inCube a pour objet de vous aider à évaluer le capital cible des Projets « Études des enfants ». Les informations communiquées sont basées sur des données statistiques publiques*. En aucun cas la responsabilité d’inCube ne saurait être invoquée pour tout problème survenant à la suite de l’utilisation du présent simulateur.
            *source : bla bla bla</p>
        </Card>
    )
};

export default Footer;

Footer.propTypes = {
    inputTotalCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    inputSlider: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    inputTotalMonthlyPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired
};