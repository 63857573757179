import * as CONSTROUTER from 'router/CONSTANT';

const navLink = [
  {
    id: 1,
    route: `${CONSTROUTER.STUDIES}`,
    label: 'Etudes',
  },
  {
    id: 2,
    route: `${CONSTROUTER.RETIREMENT}`,
    label: 'Retraite',
  },
];

export default navLink;
