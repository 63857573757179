import React from "react";

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginBottom: '0.5em',
        marginTop: '3px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2em',
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    return(
        <h2 className={classes.title}>Retraite</h2>
    )
};

export default Header;