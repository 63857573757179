import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import theme from 'config/theme';

import * as CONSTROUTER from 'router/CONSTANT';

import Studies from 'pages/Studies';
import Retirement from 'pages/Retirement';

import NavBar from 'components/NavBar';

import './App.css';

// TODO align ul on left on Studies ul, li component
// TODO onclick button suggestion for Studies total cost

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <NavBar />
        <div className="App">
          <Switch>
            <Route exact path={CONSTROUTER.STUDIES}>
              <Studies />
            </Route>
            <Route exact path={CONSTROUTER.RETIREMENT}>
              <Retirement />
            </Route>
          </Switch>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
