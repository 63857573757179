import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        padding: '1em',
        marginBottom: '5px'
    },
    itemWrapper: {},
    divider: {
        margin: '9px 0'
    },
    text: {
        marginBottom: '5px'
    },
    inputFlexEnd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    input: {
        width: '10em',
        marginRight: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '6em',
        },
    },
    inputTotalCost: {
        width: '10em',
        marginRight: '5px',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            width: '6em',
        },
    },
    inputRange: {
        width: '65%',
    },
    inputDurationYear: {
        width: '6em',
        marginRight: '5px',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            width: '3em',
        },
    },
    monthlyPaymentWrapper: {
        marginBottom: '5px'
    },
    marginRight: {
        marginRight: '5px'
    },
    financialBurrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '5px'
    }
}));

const Main = ({
    setInputTotalCost,
    inputTotalCost,
    setInputMoneyPerMonth,
    inputMoneyPerMonth,
    setInputDurationYear,
    inputDurationYear,
    setInputTotalMonthlyPayment,
    inputTotalMonthlyPayment,
    setInputSlider,
    inputSlider,
    duration,
    rate
}) => {
    const classes = useStyles();

    // TODO refacto with ONE onChange method
    const handleChangeInputTotalCost = (e) => {
        setInputTotalCost(Number(e.target.value));
    }

    const handleChangeInputDurationYear = (e) => {
        setInputDurationYear(Number(e.target.value));
        setInputTotalMonthlyPayment(Number(e.target.value*12*inputMoneyPerMonth))
    }
    const handleChangeInputsPaymentUser = (e) => {
        if (e.target.name === 'moneyPerMounth') {
            const moneyPerMounthValue = e.target.value;
            setInputMoneyPerMonth(Number(moneyPerMounthValue));
            setInputTotalMonthlyPayment(Math.round(Number(moneyPerMounthValue)*Number(inputDurationYear)*12))
        }
        if (e.target.name === 'totalMonthlyPayment') {
            const totalMonthlyPaymentValue = e.target.value;
            setInputTotalMonthlyPayment(Number(totalMonthlyPaymentValue));
            setInputMoneyPerMonth(Math.round(totalMonthlyPaymentValue/inputDurationYear/12));
        }
    }

    const handleSliderChange = (e) => {
        setInputSlider(Number(e.target.value));
    }

    const monthlyPaymentBorrow = Math.ceil((inputSlider * (1 + rate/100 * duration)) / (duration * 12));
    const globalBorrowCost = inputSlider * rate / 100 * duration;


    return(
        <>
          <Card className={classes.mainWrapper}>
              <div className={classes.itemWrapper}>
                  <p className={classes.text}>Coût global estimé des études :</p>
                  <div className={classes.inputFlexEnd}>
                    <input
                        className={classes.inputTotalCost}
                        type="number"
                        value={inputTotalCost}
                        min={0}
                        onChange={handleChangeInputTotalCost}
                    /> €
                  </div>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.itemWrapper}>
                    <p className={classes.text}>Part qui sera financée par vos revenus :</p>
                    <div className={classes.monthlyPaymentWrapper}>
                        <input
                            className={classes.input}
                            type="number"
                            name="moneyPerMounth"
                            value={inputMoneyPerMonth}
                            min={0}
                            onChange={handleChangeInputsPaymentUser}
                        />
                        <span className={classes.marginRight}>€/mois pendant</span>
                        <input
                            className={classes.inputDurationYear}
                            type="number"
                            value={inputDurationYear}
                            min={0}
                            onChange={handleChangeInputDurationYear}
                        />
                        <span>an(s),</span>
                    </div>
                    <div className={classes.inputFlexEnd}>
                        <span className={classes.marginRight}>soit</span>
                        <input
                            className={classes.input}
                            type="number"
                            name="totalMonthlyPayment"
                            value={inputTotalMonthlyPayment}
                            min={0}
                            onChange={handleChangeInputsPaymentUser}
                        /> €
                    </div>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.itemWrapper}>
                  <p className={classes.text}>Part financée par un prêt :</p>
                  <div
                    className={classes.financialBurrow}
                  >
                    <input
                        className={classes.inputRange}
                        type="range"
                        onChange={handleSliderChange}
                        min={0}
                        max={inputTotalCost - inputTotalMonthlyPayment}
                        step={100}
                        value={inputSlider}
                    />
                    <div>
                        <input
                            className={classes.input}
                            min={0}
                            onChange={handleSliderChange}
                            type="number"
                            value={inputSlider}
                        />€
                    </div>
                  </div>
                  <p>Soit une mensualité de remboursement de {monthlyPaymentBorrow} € / mois pendant {duration} ans (taux de {rate}%) pour un coût global de {Math.round(globalBorrowCost*10)/10} €</p>
              </div>
          </Card>
        </>
    )
};

export default Main;

Main.propTypes = {
    setInputTotalCost: PropTypes.func.isRequired,
    inputTotalCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputMoneyPerMonth: PropTypes.func.isRequired,
    inputMoneyPerMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputDurationYear: PropTypes.func.isRequired,
    inputDurationYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputTotalMonthlyPayment: PropTypes.func.isRequired,
    inputTotalMonthlyPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputSlider: PropTypes.func.isRequired,
    inputSlider: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
};

