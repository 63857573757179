import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#E8F4FD',
        main: '#2C99DD',
        contrastText: '#fff',
      },
      secondary: {
        main: '#EE6202',
        contrastText: '#000',
      },
    },
});

export default theme;