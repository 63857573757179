import React from 'react';

// import StudiesHeader from 'components/Studies/Header';
import StudiesHeaderTabPanel from 'components/Studies/HeaderTabPanel';
import StudiesMain from 'components/Studies/Main';
import StudiesFooter from 'components/Studies/Footer';
import SimpleAlert from 'components/Alert';

const Studies = () => {
    const [tabPanelValue, setTabPanelValue] = React.useState(0);

    const [inputSlider, setInputSlider] = React.useState(0);
    const [inputTotalCost, setInputTotalCost] = React.useState(13800);
    const [inputMoneyPerMonth, setInputMoneyPerMonth] = React.useState(0);
    const [inputTotalMonthlyPayment, setInputTotalMonthlyPayment] = React.useState(0);
    const [inputDurationYear, setInputDurationYear] = React.useState(2);

    const [error, setError] = React.useState(false);

    const handleChangeTabPanel = (event, newValue) => {
      switch (newValue) {
        case 0:
          setInputTotalCost(13800);
          setInputDurationYear(2);
          break;
        case 1:
          setInputTotalCost(52800);
          setInputDurationYear(5);
          break;
        case 2:
          setInputTotalCost(84000);
          setInputDurationYear(7);
          break;

        default:
          break;
      }
      setTabPanelValue(newValue);
    };

    const duration = 10;
    const rate = 1.2;

    React.useEffect(() => {
      if (inputTotalMonthlyPayment + Number(inputSlider) >= Number(inputTotalCost)) {
        setError('Vous avez déjà atteint le coût global estimé');
      } else {
        setError(false);
      }
    }, [inputTotalMonthlyPayment, inputSlider, inputTotalCost]);

    return(
        <>
          {/* <StudiesHeader /> */}
          <StudiesHeaderTabPanel
            tabPanelValue={tabPanelValue}
            setTabPanelValue={setTabPanelValue}
            handleChangeTabPanel={handleChangeTabPanel}
          />
          <StudiesMain
            setInputSlider={setInputSlider}
            inputSlider={inputSlider}
            setInputTotalCost={setInputTotalCost}
            inputTotalCost={inputTotalCost}
            setInputMoneyPerMonth={setInputMoneyPerMonth}
            inputMoneyPerMonth={inputMoneyPerMonth}
            setInputDurationYear={setInputDurationYear}
            inputDurationYear={inputDurationYear}
            setInputTotalMonthlyPayment={setInputTotalMonthlyPayment}
            inputTotalMonthlyPayment={inputTotalMonthlyPayment}
            duration={duration}
            rate={rate}
          />
          {error && (<SimpleAlert textContent={error} />)}
          <StudiesFooter
            error={error}
            inputTotalCost={inputTotalCost}
            inputTotalMonthlyPayment={inputTotalMonthlyPayment}
            inputSlider={inputSlider}
          />
        </>
    )
};

export default Studies;