import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { getReplacementRate } from 'utils';

import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    subMainWrapper: {
        padding: '0.5em',
        marginBottom: '1em'
    },
    subtitle: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginBottom: '0.4em',

    },
    divider: {
        margin: '9px 0'
    },
    itemWrapper: {},
    retirementSynthesisWrapper: {
        padding: '3px',
        backgroundColor: theme.palette.primary.light,
        fontWeight: 'bold'
    },
    inputWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        width: '10em',
        marginRight: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '6em',
        },
    },
    smallInput: {
        width: '6em',
        marginRight: '5px',
        [theme.breakpoints.down('xs')]: {
            width: '4em',
        },
    },
    orangeSpan: {
        color: theme.palette.secondary.main
    }
}));

const Main = ({
    setInputGrossYearPayment,
    inputGrossYearPayment,
    setInputMonthlyPayment,
    inputMonthlyPayment,
    setInputReplacementRate,
    inputReplacementRate,
    setInputRetirementPaymentNet,
    inputRetirementPaymentNet,
    setInputSelectRetirementYear,
    inputSelectRetirementYear,
    setInputRetirementComplementary,
    inputRetirementComplementary
}) => {
    const classes = useStyles();
    const retirementPaymentNet = Math.round(inputMonthlyPayment*inputReplacementRate/100);

    const handleChangeSalary = (e) => {
        if (e.target.name === 'grossYearPayment') {
            const grossYearPaymentValue = e.target.value;
            setInputGrossYearPayment(Number(grossYearPaymentValue));
            setInputMonthlyPayment(Math.round(grossYearPaymentValue*0.73/12));
            setInputReplacementRate(getReplacementRate(Math.round(grossYearPaymentValue*0.73/12)));
            setInputRetirementPaymentNet(Math.round(grossYearPaymentValue*0.73/12*getReplacementRate(Math.round(grossYearPaymentValue*0.73/12))/100));
        }
        if (e.target.name === 'monthlyPayment') {
            const mounthlyPaymentValue = e.target.value;
            setInputMonthlyPayment(Number(mounthlyPaymentValue));
            setInputGrossYearPayment(Math.round(mounthlyPaymentValue/0.73*12));
            setInputReplacementRate(getReplacementRate(mounthlyPaymentValue));
            setInputRetirementPaymentNet(Math.round(mounthlyPaymentValue*getReplacementRate(mounthlyPaymentValue)/100));
        }
    };
    const handleChangeReplacementRate = (e) => {
        setInputReplacementRate(Number(e.target.value));
    };
    const handleChangeRetirementPaymentNet = (e) => {
        setInputRetirementPaymentNet(Number(e.target.value));
    };
    const handleChangeInputSelectRetirementYear = (e) => {
        setInputSelectRetirementYear(Number(e.target.value));
    }
    const handleChangeInputRetirementComplementary = (e) => {
        setInputRetirementComplementary(Number(e.target.value));
    };



    return(
        <>
            <Card className={classes.subMainWrapper}>
                <h3 className={classes.subtitle}>Estimation de pension de retraite</h3>
                <div className={classes.itemWrapper}>
                    <div>
                        <p style={{marginBottom: '0.2em'}}>Salaire brut annuel</p>
                        <div className={classes.inputWrapper}>
                            <input
                                type="number"
                                className={classes.input}
                                value={inputGrossYearPayment}
                                min={0}
                                name='grossYearPayment'
                                onChange={handleChangeSalary}
                            />
                            <span style={{marginRight: '0.3em'}}>€/an soit</span>
                            <input
                                type="number"
                                className={classes.input}
                                value={inputMonthlyPayment}
                                min={0}
                                name='monthlyPayment'
                                onChange={handleChangeSalary}
                            />
                            {'€ net/mois'}
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                    <div>
                        <p style={{marginBottom: '0.2em'}}>Taux de remplacement</p>
                        <div className={classes.inputWrapper}>
                            <input
                                type="number"
                                className={classes.smallInput}
                                value={inputReplacementRate}
                                onChange={handleChangeReplacementRate}
                            />
                            <span style={{marginRight: '0.3em'}}>%</span>
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.retirementSynthesisWrapper}>
                        <p style={{marginBottom: '0.2em'}}>Pension de retraite nette</p>
                        <div className={classes.inputWrapper}>
                            <span className={classes.orangeSpan}>{retirementPaymentNet} €/mois</span>
                        </div>
                    </div>
                </div>
            </Card>
            <Card className={classes.subMainWrapper}>
                <h3 className={classes.subtitle}>Calcul du complément de revenus</h3>
                <div className={classes.itemWrapper}>
                    <div>
                        <p style={{marginBottom: '0.2em'}}>Pension de retraite nette</p>
                        <div className={classes.inputWrapper}>
                            <input
                                type="number"
                                className={classes.input}
                                onChange={handleChangeRetirementPaymentNet}
                                value={inputRetirementPaymentNet}
                            />
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                    <div>
                        <p style={{marginBottom: '0.2em'}}>Rente viagère mensuelle souhaitée</p>
                        <div className={classes.inputWrapper}>
                            <input
                                type="number"
                                className={classes.input}
                                name="retirementComplementary"
                                value={inputRetirementComplementary}
                                onChange={handleChangeInputRetirementComplementary}
                            />
                            <span style={{marginRight: '0.8em'}}>€/mois </span>
                            <span style={{marginRight: '0.3em'}}>à quelle âge</span>
                            <select
                                onChange={handleChangeInputSelectRetirementYear}
                                name="retirementYear"
                                id="retirementYear"
                                className={classes.smallInput}
                                defaultValue={inputSelectRetirementYear}
                            >
                                <option value={55}>55</option>
                                <option value={60}>60</option>
                                <option value={65}>65</option>
                                <option value={70}>70</option>
                            </select>
                            <span>ans</span>
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.retirementSynthesisWrapper}>
                        <p style={{marginBottom: '0.2em'}}>Revenus globaux nets perçus</p>
                        <div className={classes.inputWrapper}>
                            <span style={{marginRight: '0.8em'}} className={classes.orangeSpan}>{inputRetirementPaymentNet + inputRetirementComplementary} €/mois </span>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
};

export default Main;

Main.propTypes = {
    setInputGrossYearPayment: PropTypes.func.isRequired,
    inputGrossYearPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputMonthlyPayment: PropTypes.func.isRequired,
    inputMonthlyPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputReplacementRate: PropTypes.func.isRequired,
    inputReplacementRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputRetirementPaymentNet: PropTypes.func.isRequired,
    inputRetirementPaymentNet: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputSelectRetirementYear: PropTypes.func.isRequired,
    inputSelectRetirementYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    setInputRetirementComplementary: PropTypes.func.isRequired,
    inputRetirementComplementary: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
};
