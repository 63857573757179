import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';

import logo from 'assets/img/pics_incube.webp';

import navLink from 'constants/navLink';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  appBarWrapper: {
    padding: '0.5em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: "white"
  },
  logo: {
    height: 'auto',
    width: '6em',
    marginRight: '2em'
  },
  link: {
    fontFamily: 'popins, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    color: '#828282',
    margin: '0 0.9em',
    '&:hover': {
      color: '#3CAFE5',
    },
    textDecoration: "none"
  },
  activeClassLink: {
    color: '#3CAFE5',
    borderBottom: '2px #3CAFE5 solid',
    paddingBottom: '20px',
    fontWeight: 'bold'
  },

}));

export default function NavBar() {
  const classes = useStyles();

  const NavLinkJSX = navLink.map((link) => (
    <NavLink
      exact
      key={link.label}
      to={`${link.route}`}
      className={classes.link}
      activeClassName={classes.activeClassLink}
    >
      {link.label}
    </NavLink>
  ));

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBarWrapper}>
          <img className={classes.logo} src={logo} alt="incube-logo" />
        <div className={classes.containerNavlink}>{NavLinkJSX}</div>
      </AppBar>
    </div>
  );
}