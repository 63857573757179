const studiesData = [
    {
        id: 1,
        tabPanelValue: 0,
        moneyPerYear: 6900,
        durationYear: 2,
        totalCost: 13800,
        study: 'BTS/IUT',
        accommodation: 'domicile familiale'
    },
    {
        id: 2,
        tabPanelValue: 1,
        moneyPerYear: 10500,
        durationYear: 5,
        totalCost: 52800,
        study: 'cycle universitaire',
        accommodation: 'individuel ou colocation'
    },
    {
        id: 3,
        tabPanelValue: 2,
        moneyPerYear: 12000,
        durationYear: 7,
        totalCost: 84000,
        study: 'école d\'ingénieur ou de commerce',
        accommodation: 'individuel ou colocation'
    },
];

export default studiesData;