import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import studiesData from 'constants/studiesData';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            <div>{children}</div>
          </Box>
        )}
      </div>
    );

}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };

}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginBottom: '0.5em',
        marginTop: '3px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2em',
        },
    },
    tabPanel: {
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center'
        // [theme.breakpoints.down('xs')]: {
        //     margin: '2px 2px'
        // },
    },
    hypothesis: {
        margin: '8px',
        padding: '10px',
        textAlign: 'center',
        width: '25em',
        [theme.breakpoints.down('xs')]: {
            margin: '4px',
            width: '20em'
        },
    },
    subtitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '0.5em',
        color: theme.palette.secondary.main,
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 'bold',
        marginBottom: '5px'
    },
    accordionDetailsTitle: {
        marginBottom: '1em'
    },
    listContainer: {
        color: 'gray'
    },
    colorOrange: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold'
    }
}));

const Header = ({ tabPanelValue ,setTabPanelValue, handleChangeTabPanel }) => {
    const classes = useStyles();
    const theme = useTheme();

    const handleChangeTabPanelIndex = (index) => {
      setTabPanelValue(index);
    };

    return(
        <>
            <div className={classes.root}>
                <h2 className={classes.title}>Estimation des coûts de la scolarité</h2>
                <h3 className={classes.subtitle}>Hypothèses</h3>
                <AppBar position="static" color="default">
                    <Tabs
                        inkbarstyle={{background: 'red'}}
                        value={tabPanelValue}
                        onChange={handleChangeTabPanel}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs"
                    >
                    <Tab label="basse" {...a11yProps(0)} />
                    <Tab label="moyenne" {...a11yProps(1)} />
                    <Tab label="haute" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tabPanelValue}
                    onChangeIndex={handleChangeTabPanelIndex}
                >
                    {studiesData.map((d) => {
                        return (
                            <TabPanel
                                key={d.id + 122}
                                className={classes.tabPanel}
                                value={d.tabPanelValue}
                                index={d.tabPanelValue}
                                dir={theme.direction}
                            >
                                <Card className={classes.hypothesis}>
                                    <Typography className={classes.secondaryHeading}>{d.moneyPerYear}€ par an</Typography>
                                    <h4 className={classes.accordionDetailsTitle}>
                                        pendant <span className={classes.colorOrange}>{d.durationYear}</span> soit <span className={classes.colorOrange}>{d.totalCost}€</span>
                                    </h4>
                                    <ul className={classes.listContainer}>
                                        <li>type d’étude: {d.study}</li>
                                        <li>durée: {d.durationYear} ans</li>
                                        <li>hébergement: {d.accommodation}</li>
                                    </ul>
                                </Card>
                            </TabPanel>
                        )
                    })}
                </SwipeableViews>
            </div>
        </>
    )
};

export default Header;

Header.propTypes = {
    setTabPanelValue: PropTypes.func.isRequired,
    handleChangeTabPanel: PropTypes.func.isRequired,
    tabPanelValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
};