import React from 'react';

import RetirementHeader from 'components/Retirement/Header';
import RetirementMain from 'components/Retirement/Main';
import RetirementFooter from 'components/Retirement/Footer';

const Retirement = () => {
    const [inputGrossYearPayment, setInputGrossYearPayment] = React.useState(0);
    const [inputMonthlyPayment, setInputMonthlyPayment] = React.useState(0);
    const [inputReplacementRate, setInputReplacementRate] = React.useState(0);
    const [inputRetirementPaymentNet, setInputRetirementPaymentNet] = React.useState(0);
    const [inputSelectRetirementYear, setInputSelectRetirementYear] = React.useState(65);
    const [inputRetirementComplementary, setInputRetirementComplementary] = React.useState(0);

    return(
        <>
            <RetirementHeader />
            <RetirementMain
                setInputGrossYearPayment={setInputGrossYearPayment}
                inputGrossYearPayment={inputGrossYearPayment}
                setInputMonthlyPayment={setInputMonthlyPayment}
                inputMonthlyPayment={inputMonthlyPayment}
                setInputReplacementRate={setInputReplacementRate}
                inputReplacementRate={inputReplacementRate}
                setInputRetirementPaymentNet={setInputRetirementPaymentNet}
                inputRetirementPaymentNet={inputRetirementPaymentNet}
                setInputSelectRetirementYear={setInputSelectRetirementYear}
                inputSelectRetirementYear={inputSelectRetirementYear}
                setInputRetirementComplementary={setInputRetirementComplementary}
                inputRetirementComplementary={inputRetirementComplementary}
            />
            <RetirementFooter
                inputSelectRetirementYear={inputSelectRetirementYear}
                inputRetirementComplementary={inputRetirementComplementary}
            />
        </>
    )
};

export default Retirement;
