export const getReplacementRate = (monthlySalaryNet) => {
    if( monthlySalaryNet === 0) return  0;
    if( monthlySalaryNet < 1000) return 95;
    if( monthlySalaryNet < 1250) return 82;
    if( monthlySalaryNet < 1500) return 83;
    if( monthlySalaryNet < 1750) return 80;
    if( monthlySalaryNet < 2000) return 79;
    if( monthlySalaryNet < 2250) return 77;
    if( monthlySalaryNet < 2500) return 78;
    if( monthlySalaryNet < 2750) return 76;
    if( monthlySalaryNet < 3000) return 75;
    if( monthlySalaryNet < 3250) return 72;
    if( monthlySalaryNet < 3500) return 73;
    if( monthlySalaryNet < 3750) return 72;
    if( monthlySalaryNet < 4000) return 67;
    return 58;
};

export const getConversionRate = (age) => {
    if( age === 55 ) return 0.036;
    if( age === 60 ) return 0.0354;
    if( age === 65 ) return 0.0421;
    if( age === 70 ) return 0.0517;
};